<script>
import {
  required,
  requiredIf,
  maxLength,
  minLength,
} from "vuelidate/lib/validators";

export default {
  name: "AddressFormGroup",
  props: {
    incomingAddressCityValue: {
      type: String,
      required: false,
    },
    incomingAddressStreetValue: {
      type: String,
      required: false,
    },
    incomingAddressHouseNoValue: {
      type: String,
      required: false,
    },
    incomingAddressFlatNoValue: {
      type: String,
      required: false,
    },
    incomingAddressPostalCodeValue: {
      type: String,
      required: false,
    },
    incomingAddressInternationalValue: {
      type: String,
      required: false,
    },
    withoutAIField: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      whitoutAI: false,
      addressCity: "",
      addressStreet: "",
      addressHouseNo: "",
      addressFlatNo: "",
      addressPostalCode: "",
      addressInternational: "",
    };
  },
  validations: {
    addressCity: {
      required: requiredIf(function () {
        if (this.addressInternational == null || this.addressInternational == '') {
          return true;
        }
        return false;
      }),
      maxLength: maxLength(255),
      minLength: minLength(2),
    },
    addressStreet: {
      required: requiredIf(function () {
        if (this.addressInternational == null || this.addressInternational == '') {
          return true;
        }
        return false;
      }),
      maxLength: maxLength(255),
      minLength: minLength(2),
    },
    addressHouseNo: {
      required: requiredIf(function () {
        if (this.addressInternational == null || this.addressInternational == '') {
          return true;
        }
        return false;
      }),
      maxLength: maxLength(20),
      minLength: minLength(1),
    },
    addressFlatNo: {
      required: false,
      maxLength: maxLength(20),
      minLength: minLength(1),
    },
    addressPostalCode: {
      required: requiredIf(function () {
        if (this.addressInternational == null || this.addressInternational == '') {
          return true;
        }
        return false;
      }),
      maxLength: maxLength(20),
      minLength: minLength(1),
    },
    addressInternational: {
      required: false,
      maxLength: maxLength(255),
      minLength: minLength(2),
    },
  },
  created() {
    if (this.incomingAddressCityValue) {
      this.addressCity = this.incomingAddressCityValue;
    }
    if (this.incomingAddressStreetValue) {
      this.addressStreet = this.incomingAddressStreetValue;
    }
    if (this.incomingAddressHouseNoValue) {
      this.addressHouseNo = this.incomingAddressHouseNoValue;
    }
    if (this.incomingAddressFlatNoValue) {
      this.addressFlatNo = this.incomingAddressFlatNoValue;
    }
    if (this.incomingAddressPostalCodeValue) {
      this.addressPostalCode = this.incomingAddressPostalCodeValue;
    }
    if (this.incomingAddressInternationalValue) {
      this.addressInternational = this.incomingAddressInternationalValue;
    }
    if (this.withoutAIField) {
      this.whitoutAI = this.withoutAIField;
    }
  },
  watch: {
    incomingAddressCityValue: function () {
      this.addressCity = this.incomingAddressCityValue;
    },
    incomingAddressStreetValue: function () {
      this.addressStreet = this.incomingAddressStreetValue;
    },
    incomingAddressHouseNoValue: function () {
      this.addressHouseNo = this.incomingAddressHouseNoValue;
    },
    incomingAddressFlatNoValue: function () {
      this.addressFlatNo = this.incomingAddressFlatNoValue;
    },
    incomingAddressPostalCodeValue: function () {
      this.addressPostalCode = this.incomingAddressPostalCodeValue;
    },
    incomingAddressInternationalValue: function () {
      this.addressInternational = this.incomingAddressInternationalValue;
    },
    addressCity: function () {
      this.$emit("address-city-form-group", this.addressCity);
    },
    addressStreet: function () {
      this.$emit("address-street-form-group", this.addressStreet);
    },
    addressHouseNo: function () {
      this.$emit("address-house-no-form-group", this.addressHouseNo);
    },
    addressFlatNo: function () {
      this.$emit("address-flat-no-form-group", this.addressFlatNo);
    },
    addressPostalCode: function () {
      this.$emit("address-postal-code-form-group", this.addressPostalCode);
    },
    addressInternational: function () {
      this.$emit("address-international-form-group", this.addressInternational);
    },
    withoutAIField: function () {
      this.whitoutAI = this.withoutAIField;
      if (this.whitoutAI) {
        this.addressInternational = null;
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-lg-8">
      <div class="form-group">
        <label for="addressCity">
          Miasto
          <em class="text-danger" v-if="addressInternational == null || addressInternational == ''">*</em>
        </label>
        <input id="addressCity" v-model.trim="addressCity" @input="$v.addressCity.$touch()" class="form-control" :class="{ 'is-invalid': $v.addressCity.$error, 'is-valid': !$v.addressCity.$invalid && !$v.addressCity.$error }" type="text" name="addressCity" placeholder="Wpisz miasto" value />
        <div v-if="$v.addressCity.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.addressCity.$error && !$v.addressCity.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.addressCity.$error && !$v.addressCity.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.addressCity.$error && !$v.addressCity.minLength">Zbyt mała ilość znaków!</span>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="form-group">
        <label for="addressPostalCode">
          Kod pocztowy
          <em class="text-danger" v-if="addressInternational == null || addressInternational == ''">*</em>
        </label>
        <input id="addressPostalCode" v-model.trim="addressPostalCode" @input="$v.addressPostalCode.$touch()" class="form-control" :class="{ 'is-invalid': $v.addressPostalCode.$error, 'is-valid': !$v.addressPostalCode.$invalid && !$v.addressPostalCode.$error }" type="text" name="addressPostalCode" placeholder="Wpisz kod pocztowy" value />
        <div v-if="$v.addressPostalCode.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.addressPostalCode.$error && !$v.addressPostalCode.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.addressPostalCode.$error && !$v.addressPostalCode.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.addressPostalCode.$error && !$v.addressPostalCode.minLength">Zbyt mała ilość znaków!</span>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div class="form-group">
        <label for="addressStreet">
          Ulica
          <em class="text-danger" v-if="addressInternational == null || addressInternational == ''">*</em>
        </label>
        <input id="addressStreet" v-model.trim="addressStreet" @input="$v.addressStreet.$touch()" class="form-control" :class="{ 'is-invalid': $v.addressStreet.$error, 'is-valid': !$v.addressStreet.$invalid && !$v.addressStreet.$error }" type="text" name="addressStreet" placeholder="Wpisz ulicę" value />
        <div v-if="$v.addressStreet.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.addressStreet.$error && !$v.addressStreet.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.addressStreet.$error && !$v.addressStreet.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.addressStreet.$error && !$v.addressStreet.minLength">Zbyt mała ilość znaków!</span>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="form-group">
        <label for="addressHouseNo">
          Numer domu
          <em class="text-danger" v-if="addressInternational == null || addressInternational == ''">*</em>
        </label>
        <input id="addressHouseNo" v-model.trim="addressHouseNo" @input="$v.addressHouseNo.$touch()" class="form-control" :class="{ 'is-invalid': $v.addressHouseNo.$error, 'is-valid': !$v.addressHouseNo.$invalid && !$v.addressHouseNo.$error }" type="text" name="addressHouseNo" placeholder="Wpisz nr domu" value />
        <div v-if="$v.addressHouseNo.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.addressHouseNo.$error && !$v.addressHouseNo.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.addressHouseNo.$error && !$v.addressHouseNo.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.addressHouseNo.$error && !$v.addressHouseNo.minLength">Zbyt mała ilość znaków!</span>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="form-group">
        <label for="addressFlatNo">
          Numer lokalu
        </label>
        <input id="addressFlatNo" v-model.trim="addressFlatNo" @input="$v.addressFlatNo.$touch()" class="form-control" :class="{ 'is-invalid': $v.addressFlatNo.$error, 'is-valid': !$v.addressFlatNo.$invalid && !$v.addressFlatNo.$error }" type="text" name="addressFlatNo" placeholder="Wpisz nr lokalu" value />
        <div v-if="$v.addressFlatNo.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.addressFlatNo.$error && !$v.addressFlatNo.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.addressFlatNo.$error && !$v.addressFlatNo.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.addressFlatNo.$error && !$v.addressFlatNo.minLength">Zbyt mała ilość znaków!</span>
        </div>
      </div>
    </div>
    <div class="col-lg-12" v-if="!whitoutAI">
      <div class="form-group">
        <label for="addressInternational">
          Adres zagraniczny
        </label>
        <input id="addressInternational" v-model.trim="addressInternational" @input="$v.addressInternational.$touch()" class="form-control" :class="{ 'is-invalid': $v.addressInternational.$error, 'is-valid': !$v.addressInternational.$invalid && !$v.addressInternational.$error }" type="text" name="addressInternational" placeholder="Wpisz adres zagraniczny" value />
        <div v-if="$v.addressInternational.$error" class="invalid-feedback">
          <span class="d-block" v-if="$v.addressInternational.$error && !$v.addressInternational.required">Pole jest wymagane!</span>
          <span class="d-block" v-if="$v.addressInternational.$error && !$v.addressInternational.maxLength">Przekroczona dozwolona ilość znaków!</span>
          <span class="d-block" v-if="$v.addressInternational.$error && !$v.addressInternational.minLength">Zbyt mała ilość znaków!</span>
        </div>
      </div>
    </div>
  </div>
</template>
