const dataSourceOfData = [
  {
    "id": 1,
    "name": "SAP",
    "description": "SAP",
  },
  {
    "id": 2,
    "name": "IMPULS",
    "description": "IMPULS",
  },
];

export { dataSourceOfData };
