<script>
import Vue from "vue";
import Layout from "@/router/layouts/main";
import PageHeader from "@/components/page-header";
import Preloader from "@/components/preloader";
import Multiselect from "vue-multiselect";
import Switches from "vue-switches";
import AddressFormGroup from "@/components/form-elements/address-form-group";
import appConfig from "@/app.config";
import {
  required,
  maxLength,
  minLength,
  numeric,
  helpers,
} from "vuelidate/lib/validators";
import Repository from "@/app/repository/repository-factory";
import { roleService } from "@/app/service/roleService";
import { dataSourceOfData } from "@/data/data-source-of-data";

const PaymentCompanyRepository = Repository.get("PaymentCompanyRepository");

const alphaNumNoSpecials = helpers.regex(
  "alphaNumNoSpecials",
  /^[a-zżźćńółęąśA-ZŻŹĆĄŚĘŁÓŃ0-9]*$/
);

/**
 * Edycja firmy
 */
export default {
  page: {
    title: "Edytuj firmę",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Preloader,
    Multiselect,
    Switches,
    AddressFormGroup,
  },
  data() {
    return {
      header: {
        title: "Edytuj firmę",
        items: [
          {
            text: "Strona główna",
            to: { name: "home" },
          },
          {
            text: "Lista firm",
            to: { name: "Lista firm" },
          },
          {
            text: "Edycja firmy",
          },
        ],
      },
      preloader: true,
      disabledBtn: false,
      sourceOfDataSelectOptions: dataSourceOfData,
      form: {
        nip: "",
        name: "",
        address: "",
        addressCity: "",
        addressStreet: "",
        addressHouseNo: "",
        addressFlatNo: "",
        addressPostalCode: "",
        addressInternational: "",
        taxAddressCity: "",
        taxAddressStreet: "",
        taxAddressHouseNo: "",
        taxAddressFlatNo: "",
        taxAddressPostalCode: "",
        taxAddressInternational: "",
        sourceOfData: null,
        symbol: "",
        businessUnitCode: "",
        isActive: false,
        taxAuthorityName: "",
        taxAuthorityBankAccount: "",
        taxAuthorityAddress: "",
      },
    };
  },
  validations: {
    form: {
      nip: {
        required,
        maxLength: maxLength(10),
        minLength: minLength(10),
        numeric,
        validTaxNumber: (value) => {
          if (value) {
            let weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
            value = value.replace(/[\s-]/g, "");

            if (value.length === 10 && parseInt(value, 10) > 0) {
              let sum = 0;
              for (let i = 0; i < 9; i++) {
                sum += value[i] * weights[i];
              }
              return sum % 11 === Number(value[9]);
            }
            return false;
          } else {
            return false;
          }
        },
      },
      name: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(2),
      },
      // address: {
      //   required,
      //   maxLength: maxLength(255),
      //   minLength: minLength(2),
      // },
      sourceOfData: {
        required,
      },
      symbol: {
        required,
        alphaNumNoSpecials,
        maxLength: maxLength(4),
        minLength: minLength(1),
      },
      businessUnitCode: {
        required,
        alphaNumNoSpecials,
        maxLength: maxLength(4),
        minLength: minLength(4),
      },
      taxAuthorityName: {
        required,
        maxLength: maxLength(255),
        minLength: minLength(2),
      },
      taxAuthorityBankAccount: {
        required,
        maxLength: maxLength(26),
        minLength: minLength(26),
        numeric,
      },
      // taxAuthorityNip: {
      //   required,
      //   maxLength: maxLength(10),
      //   minLength: minLength(10),
      //   numeric,
      //   validTaxNumber: (value) => {
      //     let weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
      //     value = value.replace(/[\s-]/g, "");

      //     if (value.length === 10 && parseInt(value, 10) > 0) {
      //       let sum = 0;
      //       for (let i = 0; i < 9; i++) {
      //         sum += value[i] * weights[i];
      //       }
      //       return sum % 11 === Number(value[9]);
      //     }
      //     return false;
      //   },
      // },
      // taxAuthorityAddress: {
      //   required,
      //   maxLength: maxLength(255),
      //   minLength: minLength(2),
      // },
    },
  },
  created() {
    this.getCompany();
  },
  methods: {
    getCompany() {
      if (this.$route.params.id) {
        this.preloader = true;
        PaymentCompanyRepository.get(this.$route.params.id)
          .then((response) => {
            this.form = response.data;
            this.preloader = false;
          })
          .catch((error) => {
            this.preloader = false;
            console.log(error);
          });
      }
    },
    updateCompany() {
      this.formSubmit();

      if (
        this.$v.$error === true &&
        this.$refs.AddressFormGroup.$v.$invalid === true &&
        this.$refs.TaxAddressFormGroup.$v.$invalid === true
      ) {
        return false;
      }

      let payload = Object.assign({}, this.form);
      payload.sourceOfData = this.form.sourceOfData.id;

      this.disabledBtn = true;
      this.preloader = true;

      PaymentCompanyRepository.update(payload, this.$route.params.id)
        .then(() => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "success",
            toast: false,
            position: "top",
            title: "SUKCES!",
            text: "Firma została zaktualizowana!",
            showConfirmButton: false,
            timer: 3500,
            onClose: () => {
              this.$router.push({ name: "Lista firm" });
            },
          });
          this.getCompany();
        })
        .catch((error) => {
          this.disabledBtn = false;
          this.preloader = false;
          Vue.swal({
            icon: "error",
            position: "top",
            title: "Wystąpił błąd. Spróbuj jeszcze raz!",
            text: error.response.data.message,
            showConfirmButton: true,
          });
        });
    },
    formSubmit(e) {
      this.$v.$touch();
      this.$refs.AddressFormGroup.$v.$touch();
      this.$refs.TaxAddressFormGroup.$v.$touch();
    },
    getAddressCityFormValues: function (params) {
      this.form.addressCity = params;
    },
    getAddressStreetFormValues: function (params) {
      this.form.addressStreet = params;
    },
    getAddressHouseNoFormValues: function (params) {
      this.form.addressHouseNo = params;
    },
    getAddressFlatNoFormValues: function (params) {
      this.form.addressFlatNo = params;
    },
    getAddressPostalCodeFormValues: function (params) {
      this.form.addressPostalCode = params;
    },
    getAddressInternationalFormValues: function (params) {
      this.form.addressInternational = params;
    },
    getTaxAddressCityFormValues: function (params) {
      this.form.taxAddressCity = params;
    },
    getTaxAddressStreetFormValues: function (params) {
      this.form.taxAddressStreet = params;
    },
    getTaxAddressHouseNoFormValues: function (params) {
      this.form.taxAddressHouseNo = params;
    },
    getTaxAddressFlatNoFormValues: function (params) {
      this.form.taxAddressFlatNo = params;
    },
    getTaxAddressPostalCodeFormValues: function (params) {
      this.form.taxAddressPostalCode = params;
    },
    getTaxAddressInternationalFormValues: function (params) {
      this.form.taxAddressInternational = params;
    },
  },
  computed: {
    isAdmin() {
      return roleService.isAdmin();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="header.title" :items="header.items" />
    <Preloader v-if="preloader" />
    <b-form @submit.prevent="formSubmit" v-if="isAdmin">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow-sm">
            <b-card-header>
              <div class="row align-items-center">
                <div class="col">Formularz edycji firmy</div>
                <div class="col text-right">
                  <b-link class="btn btn-sm btn-warning" :to="{ name: 'Lista firm' }">Anuluj</b-link>
                </div>
              </div>
            </b-card-header>
            <div class="card-body">
              <div class="row justify-content-center">
                <div class="col-md-5">

                  <el-divider>Dane firmy</el-divider>

                  <div class="form-group">
                    <label for="name">Nazwa firmy<em class="text-danger">*</em>
                    </label>
                    <input id="name" v-model.trim="form.name" @input="$v.form.name.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.name.$error, 'is-valid': !$v.form.name.$invalid && !$v.form.name.$error }" type="text" name="name" placeholder="Wpisz nazwę firmy" value required />
                    <div v-if="$v.form.name.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.name.$error && !$v.form.name.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.name.$error && !$v.form.name.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.name.$error && !$v.form.name.minLength">Zbyt mała ilość znaków!</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="nip">NIP firmy<em class="text-danger">*</em>
                    </label>
                    <input id="nip" v-model.trim="form.nip" @input="$v.form.nip.$touch()" v-mask="'##########'" class="form-control" :class="{ 'is-invalid': $v.form.nip.$error, 'is-valid': !$v.form.nip.$invalid && !$v.form.nip.$error }" type="text" name="nip" placeholder="Wpisz NIP firmy" value required />
                    <div v-if="$v.form.nip.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.nip.$error && !$v.form.nip.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.nip.$error && !$v.form.nip.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.nip.$error && !$v.form.nip.minLength">Zbyt mała ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.nip.$error && !$v.form.nip.numeric">Pole przyjmuje wyłącznie wartości liczbowe!</span>
                      <span class="d-block" v-if="$v.form.nip.$error && !$v.form.nip.validTaxNumber">Niepoprawny numer nip!</span>
                    </div>
                  </div>

                  <div class="form-group">
                    <label for="sourceOfData">Wybierz źródło<em class="text-danger">*</em>
                    </label>
                    <multiselect id="sourceOfData" v-model.trim="form.sourceOfData" :options="sourceOfDataSelectOptions" :class="{ 'is-invalid': $v.form.sourceOfData.$error, 'is-valid': !$v.form.sourceOfData.$invalid && !$v.form.sourceOfData.$error }" track-by="id" name="sourceOfData" placeholder="Wybierz źródło z listy" select-label deselect-label="Odznacz ✕" selected-label="Wybrane ✓" value required>
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.name }}</span>
                        </div>
                      </template>
                      <template slot="noOptions">Lista jest pusta.</template><template slot="noResult">Nie znaleziono elementów.</template>
                    </multiselect>
                  </div>

                  <div class="row pb-0">
                    <div class="col-lg-6">
                      <div class="form-group mb-0">
                        <!--  spaghetti code : switched symbol label with businessUnitCode label to make readability of company code for enduser -->
                        <label for="sap-symbol">Kod jednostki gospodarczej<em class="text-danger">*</em>
                        </label>
                        <input id="sap-symbol" v-model.trim="form.symbol" @input="$v.form.symbol.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.symbol.$error, 'is-valid': !$v.form.symbol.$invalid && !$v.form.symbol.$error }" type="text" name="sap-symbol" placeholder="Wpisz kod jednostki gospodarczej" value required />
                        <div v-if="$v.form.symbol.$error" class="invalid-feedback">
                          <span class="d-block" v-if="$v.form.symbol.$error && !$v.form.symbol.required">Pole jest wymagane!</span>
                          <span class="d-block" v-if="$v.form.symbol.$error && !$v.form.symbol.maxLength">Przekroczona dozwolona ilość znaków!</span>
                          <span class="d-block" v-if="$v.form.symbol.$error && !$v.form.symbol.minLength">Zbyt mała ilość znaków!</span>
                          <span class="d-block" v-if="$v.form.symbol.$error && !$v.form.symbol.alphaNumNoSpecials">Wpisano niedozwolone znaki!</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group mb-0">
                        <!--  spaghetti code : switched symbol label with businessUnitCode label to make readability of company code for enduser -->
                        <label for="businessUnitCode">Symbol<em class="text-danger">*</em>
                        </label>
                        <input id="businessUnitCode" v-model.trim="form.businessUnitCode" @input="$v.form.businessUnitCode.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.businessUnitCode.$error, 'is-valid': !$v.form.businessUnitCode.$invalid && !$v.form.businessUnitCode.$error }" type="text" name="businessUnitCode" placeholder="Wpisz symbol" value required />
                        <div v-if="$v.form.businessUnitCode.$error" class="invalid-feedback">
                          <span class="d-block" v-if="$v.form.businessUnitCode.$error && !$v.form.businessUnitCode.required">Pole jest wymagane!</span>
                          <span class="d-block" v-if="$v.form.businessUnitCode.$error && !$v.form.businessUnitCode.maxLength">Przekroczona dozwolona ilość znaków!</span>
                          <span class="d-block" v-if="$v.form.businessUnitCode.$error && !$v.form.businessUnitCode.minLength">Zbyt mała ilość znaków!</span>
                          <span class="d-block" v-if="$v.form.businessUnitCode.$error && !$v.form.businessUnitCode.alphaNumNoSpecials">Wpisano niedozwolone znaki!</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <el-divider class="mt-5">Adres firmy</el-divider>

                  <!-- <div class="form-group">
                    <label for="providerAddressField">
                      Adres firmy
                      <em class="text-danger">*</em>
                    </label>
                    <input id="providerAddressField" v-model.trim="form.address" @input="$v.form.address.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.address.$error, 'is-valid': !$v.form.address.$invalid && !$v.form.address.$error }" type="text" name="providerAddressField" placeholder="Wpisz adres firmy" value required />
                    <div v-if="$v.form.address.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.address.$error && !$v.form.address.required">Pole jest wymagane!</span>
                      <span class="d-block" v-if="$v.form.address.$error && !$v.form.address.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.address.$error && !$v.form.address.minLength">Zbyt mała ilość znaków!</span>
                    </div>
                  </div> -->

                  <AddressFormGroup @address-city-form-group="getAddressCityFormValues" @address-street-form-group="getAddressStreetFormValues" @address-house-no-form-group="getAddressHouseNoFormValues" @address-flat-no-form-group="getAddressFlatNoFormValues" @address-postal-code-form-group="getAddressPostalCodeFormValues" @address-international-form-group="getAddressInternationalFormValues" :incomingAddressCityValue="form.addressCity" :incomingAddressStreetValue="form.addressStreet" :incomingAddressHouseNoValue="form.addressHouseNo" :incomingAddressFlatNoValue="form.addressFlatNo" :incomingAddressPostalCodeValue="form.addressPostalCode" :incomingAddressInternationalValue="form.addressInternational" name="AddressFormGroup" ref="AddressFormGroup" />

                </div>
                <div class="col-md-5 offset-md-1">

                  <el-divider>Dane organu podatkowego</el-divider>

                  <div class="form-group">
                    <label for="taxAuthorityName">Nazwa organu podatkowego<em class="text-danger">*</em></label>
                    <input id="taxAuthorityName" v-model.trim="form.taxAuthorityName" @input="$v.form.taxAuthorityName.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.taxAuthorityName.$error, 'is-valid': !$v.form.taxAuthorityName.$invalid && !$v.form.taxAuthorityName.$error }" type="text" name="taxAuthorityName" placeholder="Wpisz nazwę organu podatkowego" value required />
                    <div v-if="$v.form.taxAuthorityName.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.taxAuthorityName.$error && !$v.form.taxAuthorityName.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.taxAuthorityName.$error && !$v.form.taxAuthorityName.minLength">Zbyt mała ilość znaków!</span>
                    </div>
                  </div>

                  <!-- <div class="form-group">
                    <label for="taxAuthorityAddress">Adres organu podatkowego<em class="text-danger">*</em></label>
                    <input id="taxAuthorityAddress" v-model.trim="form.taxAuthorityAddress" @input="$v.form.taxAuthorityAddress.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.taxAuthorityAddress.$error, 'is-valid': !$v.form.taxAuthorityAddress.$invalid && !$v.form.taxAuthorityAddress.$error }" type="text" name="taxAuthorityAddress" placeholder="Wpisz adres organu podatkowego" value required />
                    <div v-if="$v.form.taxAuthorityAddress.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.taxAuthorityAddress.$error && !$v.form.taxAuthorityAddress.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.taxAuthorityAddress.$error && !$v.form.taxAuthorityAddress.minLength">Zbyt mała ilość znaków!</span>
                    </div>
                  </div> -->

                  <AddressFormGroup @address-city-form-group="getTaxAddressCityFormValues" @address-street-form-group="getTaxAddressStreetFormValues" @address-house-no-form-group="getTaxAddressHouseNoFormValues" @address-flat-no-form-group="getTaxAddressFlatNoFormValues" @address-postal-code-form-group="getTaxAddressPostalCodeFormValues" @address-international-form-group="getTaxAddressInternationalFormValues" :incomingAddressCityValue="form.taxAddressCity" :incomingAddressStreetValue="form.taxAddressStreet" :incomingAddressHouseNoValue="form.taxAddressHouseNo" :incomingAddressFlatNoValue="form.taxAddressFlatNo" :incomingAddressPostalCodeValue="form.taxAddressPostalCode" :incomingAddressInternationalValue="form.taxAddressInternational" name="TaxAddressFormGroup" :withoutAIField="true" ref="TaxAddressFormGroup" />

                  <div class="form-group">
                    <label for="taxAuthorityBankAccount">Konto bankowe organu podatkowego<em class="text-danger">*</em></label>
                    <input id="taxAuthorityBankAccount" v-model.trim="form.taxAuthorityBankAccount" v-mask="'##########################'" @input="$v.form.taxAuthorityBankAccount.$touch()" class="form-control" :class="{ 'is-invalid': $v.form.taxAuthorityBankAccount.$error, 'is-valid': !$v.form.taxAuthorityBankAccount.$invalid && !$v.form.taxAuthorityBankAccount.$error }" type="text" name="taxAuthorityBankAccount" placeholder="Wpisz konto organu podatkowego" value required />
                    <div v-if="$v.form.taxAuthorityBankAccount.$error" class="invalid-feedback">
                      <span class="d-block" v-if="$v.form.taxAuthorityBankAccount.$error && !$v.form.taxAuthorityBankAccount.maxLength">Przekroczona dozwolona ilość znaków!</span>
                      <span class="d-block" v-if="$v.form.taxAuthorityBankAccount.$error && !$v.form.taxAuthorityBankAccount.minLength">Zbyt mała ilość znaków!</span>
                    </div>
                  </div>

                  <el-divider class="mt-5">Status</el-divider>

                  <div class="d-flex justify-content-start">
                    <switches class="mt-2" v-model="form.isActive" type-bold="false" :color="form.isActive == 1 ? 'success' : 'primary'"></switches>
                    <label class="mt-1 ml-3"><span class="mr-2">Aktywna:</span>
                      <span v-if="form.isActive" class="badge badge-pill badge-soft-success font-size-14">TAK</span>
                      <span v-else class="badge badge-pill badge-soft-danger font-size-14">NIE</span>
                    </label>
                  </div>
                  <small id="cardholderNumberHelp" class="form-text text-muted mt-n2"><i class="mdi mdi-information-outline mr-1"></i>Status dostępności spółki w systemie</small>
                </div>

                <div class="col-md-12 offset-md-1">
                  <button class="btn btn-dark" v-on:click="updateCompany" :disabled="$v.form.$invalid || this.$refs.AddressFormGroup.$v.$invalid || this.$refs.TaxAddressFormGroup.$v.$invalid || disabledBtn" type="submit">Aktualizuj firmę</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </Layout>
</template>
